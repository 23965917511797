export type FormSteps =
  | 'login'
  | 'review'
  | 'paymentMethod'
  | 'paymentTransaction'
  | 'confirmation';

export type FormPages = 'login' | 'review' | 'payment' | 'confirmation';

export const indexPage: FormPages = 'login';
export const indexStep: FormSteps = 'login';

export const topLevelSteps = [
  'login',
  'review',
  'paymentMethod',
  'confirmation',
];
export const topLevelStepsIE = topLevelSteps;

export const stepToPageMap: Record<FormSteps, FormPages> = {
  login: 'login',
  review: 'review',
  paymentMethod: 'payment',
  paymentTransaction: 'payment',
  confirmation: 'confirmation',
};

export const stepToPageMapCustomerFirst = stepToPageMap;
